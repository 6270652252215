.container {
  height: 100vh;
}

.marker {
  background-image: url('../../public/dot-100.png');
  background-size: cover;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
}
